@import url("./react-date-picker-style.css");
@import url("./react-toastify.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

input:checked ~ .dot {
  @apply transform;
  @apply translate-x-3;
  @apply bg-primary;
  @apply shadow;
}

svg {
  width: inherit !important;
  height: inherit !important;
}

.textarea-form-control {
  min-height: 140px;
}

.object-cover {
  object-fit: cover;
}

.hamburger-menu {
  display: none;
}

.shadow-card {
  box-shadow: 0px 24px 96px rgb(0 0 0 / 15%);
}

.shadow-card-md {
  box-shadow: 0px 24px 48px -12px rgb(0 0 0 / 8%);
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: inline-block;
  }
}

@screen md {
  .nav-link.active::before {
    display: none;
  }

  .main-container {
    min-height: unset;
  }
}

.nav-link.active::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  @apply bg-primary;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.manage-devices-overlay::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 8px;
  width: calc(100% + 16px);
  height: calc(100% - 16px);
  z-index: -1;
  border-radius: 4px;
  opacity: 0;
  display: flex; 
  position: relative; 
  padding-top: 24px;
  padding-bottom: 24px; 
  justify-content: space-between; 
  @apply transition;
  @apply bg-gray-50;
}

.manage-devices-overlay:hover::before {
  opacity: 1;
}


/* TODO: this style is temporary, it should be removed after */
.sidebar-on .top-navigation,
.sidebar-on .sidebar-mobile {
  transform: translateX(0);
}

.sidebar-mobile,
.top-navigation {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
}

.logo-holder {
  z-index: 999;
}

.sidebar-active {
  display: inline-block !important;
  height: 100vh;
  background-color: #fff;
  z-index: 99;
}

.sidebar-on {
  overflow: hidden;
}

.sidebar-on .sidebar-on-overlay::after {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.table-wrapper tr:last-child td {
  border-color: transparent;
}

.dropdown__container {
  display: inline-flex; 
  position: absolute; 
  z-index: 10; 
  padding: 8px; 
  margin-top: 8px; 
  background-color: #ffffff; 
  flex-direction: column; 
  align-items: flex-start; 
  max-width: 320px; 
  border-radius: 4px; 
  border: 1px solid #E0E5EB; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.highlight-container, .change-password-field, .dropdown__container {
  transition: box-shadow .3s ease-in-out;
}

.highlight-container.active, .change-password-field.active, .dropdown__container.active {
  box-shadow: 0 0px 12px 10px #c029f066;
}